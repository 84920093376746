<template>
  <v-container
    fluid
    tag="section"
    id="create-devis"
  >

    <v-row justify="center">
      <v-col
        cols="12"
        md="10"
      >
        <base-material-card
          color="primary"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Créer un nouveau devis
            </div>

            <div class="subtitle-1 font-weight-light">
              Indiquez les informations
            </div>
          </template>

          <v-form
            ref="devis"
            method="post"
            @submit.prevent="validate"
          >
            <v-container class="py-0">
              <v-row class="mb-5">
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    outlined
                    label="N° devis *"
                    class="no-arrows"
                    v-model="devis.numero"
                    hint="Ex. : 123"
                    type="number"
                    :rules="[v => !!v || 'Indiquez le n°']"
                    required
                    @blur="updateReference()"
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-autocomplete
                    outlined
                    label="Destinataire *"
                    v-model="devis.clientDestinataire"
                    :items="clients"
                    item-text="nomCommercial"
                    item-value="id"
                    return-object
                    :rules="[v => !!v || 'Indiquez le destinataire']"
                    clearable
                    required
                    @change="updateReference(); formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    outlined
                    label="Référence *"
                    v-model="devis.reference"
                    hint="Exemple : FVT_123_XX"
                    :rules="[v => !!v || 'Indiquez la référence']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    outlined
                    label="N° de dossier"
                    v-model="devis.noDossier"
                    hint="N° de dossier ou n° de commande"
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-select
                    outlined
                    :items="types"
                    v-model="devis.typeFormation"
                    item-text="intitule"
                    item-value="type"
                    label="Type de formation *"
                    :rules="[v => !!v || 'Indiquez le type de formation']"
                    required
                    @change="getFormations(); formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-autocomplete
                    outlined
                    :items="courses"
                    item-text="name"
                    item-value="id"
                    label="Cours *"
                    v-model="devis.cours"
                    clearable
                    return-object
                    required
                    :rules="[v => !!v || 'Indiquez le cours']"
                    @change="getFormations(); formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    outlined
                    label="Rattacher à une formation existante"
                    v-model="devis.formationAssociee"
                    :items="formations"
                    item-text="intitule"
                    item-value="id"
                    return-object
                    clearable
                    @change="formEdited = true"
                  >
                    <template v-slot:no-data>
                      <div
                        v-if="!devis.typeFormation || !devis.cours"
                        class="px-4 py-3"
                      >
                        <i>Sélectionnez un cours et un type de formation pour faire apparaître les formations disponibles</i>
                      </div>
                      <div
                        v-else
                        class="px-4 py-3"
                      >
                        <i>Aucune formation disponible</i>
                      </div>
                    </template>
                    <template
                      slot="selection"
                      slot-scope="data"
                    >
                      {{ data.item.intitule }} ({{ data.item.reference }}) du {{ new Date(data.item.dateDebut).toLocaleString().substring(0, 10) }} ({{ data.item.type }})
                    </template>
                    <template
                      slot="item"
                      slot-scope="data"
                    >
                      {{ data.item.intitule }} ({{ data.item.reference }}) du {{ new Date(data.item.dateDebut).toLocaleString().substring(0, 10) }} ({{ data.item.type }})
                      <span v-if="data.item.etat === 'CONFIRME'">
                        <v-icon
                          class="ml-1"
                          color="green"
                        >
                          mdi-check-circle
                        </v-icon>
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    outlined
                    label="Intitulé *"
                    v-model="devis.intitule"
                    hint="Exemple : « Formation Python avancé »"
                    :rules="[v => !!v || 'Indiquez l\'intitulé du devis']"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  v-if="!devis.formationAssociee"
                  cols="6"
                  md="3"
                >
                  <v-menu
                    ref="menuDateDebut"
                    v-model="menuDateDebut"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="dateDebutFormatted"
                        label="Date de début proposée"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        @blur="devis.dateDebut = parseDate(dateDebutFormatted)"
                        @change="formEdited = true"
                      />
                    </template>
                    <v-date-picker
                      class="mt-0 create-formation-date-picker"
                      v-model="devis.dateDebut"
                      :first-day-of-week="1"
                      locale="fr"
                      @input="menuDateDebut = false; openDateFin();"
                      @change="saveDateDebut"
                      :pickerDate.sync="pickerDate"
                    />
                  </v-menu>
                </v-col>

                <v-col
                  v-if="!devis.formationAssociee"
                  cols="6"
                  md="3"
                >
                  <v-menu
                    ref="menuDateFin"
                    v-model="menuDateFin"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="dateFinFormatted"
                        label="Date de fin proposée"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!dateDebutFormatted"
                        @blur="devis.dateFin = parseDate(dateFinFormatted)"
                        @change="formEdited = true"
                      />
                    </template>
                    <v-date-picker
                      class="mt-0 create-formation-date-picker"
                      v-model="devis.dateFin"
                      :first-day-of-week="1"
                      locale="fr"
                      @input="menuDateFin = false"
                      @change="saveDateFin"
                      :pickerDate.sync="pickerDate"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <v-row
                v-if="!devis.formationAssociee"
              >
                <v-col
                  cols="6"
                  md="3"
                >
                  <v-switch
                    v-model="devis.distanciel"
                    label="La formation a lieu en classe virtuelle"
                    @change="isChezClient = false; devis.adresse = {}"
                  />
                </v-col>
                <v-col
                  v-if="!devis.distanciel && devis.typeFormation === 'INTRA'"
                  cols="6"
                  md="3"
                >
                  <v-switch
                    v-model="isChezClient"
                    label="La formation a lieu chez le client"
                    @change="fillAdresse()"
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="!devis.formationAssociee && !devis.distanciel"
              >
                <v-col
                  cols="12"
                >
                  <v-textarea
                    outlined
                    label="Adresse"
                    v-model="devis.adresse.adressePostale"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Code postal"
                    v-model="devis.adresse.codePostal"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Ville"
                    v-model="devis.adresse.ville"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Pays"
                    v-model="devis.adresse.pays"
                    @change="formEdited = true"
                  />
                </v-col>
              </v-row>

              <v-card-title>
                Informations supplémentaires
              </v-card-title>
              <v-row
                v-for="i in indexLigneInfos+1"
                :key="i"
              >
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Titre"
                    v-model="infosAdd[i-1].titre"
                    hint="Exemple : « Nombre de participants »"
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                >
                  <v-text-field
                    outlined
                    label="Valeur"
                    v-model="infosAdd[i-1].valeur"
                    @change="formEdited = true"
                  />
                </v-col>
              </v-row>
              <div class="divider mb-5">
                <v-divider />
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-btn
                      class="btnRetirerLigne"
                      v-if="indexLigneInfos > 0"
                      small
                      color="gray"
                      @click="removeLigneInfos()"
                    >
                      <v-icon class="mr-2">
                        mdi-minus-circle
                      </v-icon>
                      Retirer une ligne
                    </v-btn>
                    <v-btn
                      class="btnAjouterLigne"
                      small
                      color="gray"
                      @click="addLigneInfos()"
                    >
                      <v-icon class="mr-2">
                        mdi-plus-circle
                      </v-icon>
                      Ajouter une ligne
                    </v-btn>
                  </v-col>
                </v-row>
              </div>

              <v-card-title>
                Détail du devis
              </v-card-title>
              <v-card-text>
                <v-simple-table
                  v-if="devis.typeFormation === 'INTER'"
                  id="table-recettes-budgets-inter"
                >
                  <thead>
                    <tr>
                      <th>Nombre de stagiaires</th>
                      <th>Prix par stagiaire</th>
                      <th>Total client</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          class="mt-0 input-budget"
                          v-model="devis.nbStagiaires"
                          type="number"
                          @change="formEdited = true"
                        >
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          class="mt-0 input-budget"
                          v-model="devis.budget.cout"
                          type="number"
                          step=".01"
                          suffix="€"
                          @change="formEdited = true"
                        >
                        </v-text-field>
                      </td>
                      <td>{{ toEuro(devis.budget.cout * devis.nbStagiaires) }} HT</td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <v-simple-table
                  v-if="devis.typeFormation === 'INTRA'"
                  id="table-recettes-budgets-intra"
                >
                  <thead>
                    <tr>
                      <th>Mode de calcul</th>
                      <th>{{ displayModeCalcul}}</th>
                      <th>Total coûts pédagogiques</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <v-radio-group
                          v-model="modeCalculBudget"
                          row
                          @change="setBudgetModeCalcul()"
                        >
                          <v-radio
                            label="Prix par heure"
                            value="HEURE"
                          ></v-radio>
                          <v-radio
                            label="Prix par jour"
                            value="JOUR"
                          ></v-radio>
                          <v-radio
                            label="Prix total"
                            value="TOTAL"
                          ></v-radio>
                        </v-radio-group>
                      </td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          class="mt-0 input-budget"
                          v-model="devis.budget.cout"
                          type="number"
                          step=".01"
                          suffix="€"
                          @input="setBudgetTotalIntra($event)"
                          @change="formEdited = true"
                        >
                        </v-text-field>
                      </td>
                      <td>
                        {{ toEuro(getCoutsPedagogiquesIntra()) }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
                <!--
                <h4>Autres recettes</h4>
                ...
                -->
              <v-row
                v-for="j in indexLigneDetail"
                :key="j"
              >
                <v-col
                  cols="12"
                  md="7"
                >
                  <v-text-field
                    outlined
                    label="Désignation"
                    v-model="devis.lignesDevis[j-1].libelle"
                    hint="Exemple : « Journée de formation »"
                    :rules="[v => !!v || 'Indiquez la désignation']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="4"
                  md="1"
                >
                  <v-text-field
                    outlined
                    label="Quantité"
                    class="align-right"
                    type="number"
                    v-model="devis.lignesDevis[j-1].unites"
                    :rules="[v => !!v || 'Indiquez les unités']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="4"
                  md="2"
                >
                  <v-text-field
                    outlined
                    label="Prix unitaire"
                    class="align-right no-arrows"
                    type="number"
                    suffix="€"
                    hint="Prix unitaire hors taxe"
                    v-model="devis.lignesDevis[j-1].prixUnitaire"
                    :rules="[v => !!v || 'Indiquez le prix unitaire']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="4"
                  md="2"
                >
                  <v-text-field
                    outlined
                    label="Total"
                    class="align-right"
                    type="number"
                    suffix="€"
                    disabled
                    :value="Math.round((devis.lignesDevis[j-1].unites * devis.lignesDevis[j-1].prixUnitaire + Number.EPSILON) * 100) / 100 || 0"
                  />
                </v-col>
              </v-row>
              <div class="divider">
                <v-divider />
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-btn
                      class="btnRetirerLigne"
                      v-if="indexLigneDetail > 0"
                      small
                      color="gray"
                      @click="removeLigneDetail()"
                    >
                      <v-icon class="mr-2">
                        mdi-minus-circle
                      </v-icon>
                      Retirer une ligne
                    </v-btn>
                    <v-btn
                      class="btnAjouterLigne"
                      small
                      color="gray"
                      @click="addLigneDetail()"
                    >
                      <v-icon class="mr-2">
                        mdi-plus-circle
                      </v-icon>
                      Ajouter une ligne
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="6"
                    md="2"
                    offset-md="8"
                  >
                    Sous-total HT
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                  >
                    <v-text-field
                      v-model="devis.montantHT"
                      class="align-right"
                      type="number"
                      suffix="€"
                      hide-details
                      disabled
                      :value="calculTotalHT()"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                    offset-md="8"
                  >
                    TVA (20%)
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                  >
                    <v-text-field
                      v-model="devis.montantTVA"
                      class="align-right"
                      type="number"
                      suffix="€"
                      hide-details
                      disabled
                      :value="calculTVA()"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                    offset-md="8"
                  >
                    Montant total
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                  >
                    <v-text-field
                      v-model="devis.montantTotal"
                      class="align-right"
                      type="number"
                      suffix="€"
                      hide-details
                      disabled
                      :value="calculTotalTTC()"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    class="text-right"
                  >
                    <v-btn
                      v-if="!$route.params.id"
                      color="primary"
                      class="mr-0"
                      type="submit"
                    >
                      Créer le devis
                    </v-btn>
                    <v-btn
                      v-if="$route.params.id"
                      color="primary"
                      class="mr-0"
                      type="submit"
                      @click="updateDevis()"
                      :disabled="updateDisabled"
                    >
                      Mettre à jour le devis
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    data() {
      return {
        formEdited: false,
        clients: [],
        courses: [],
        indexLigneDetail: 0,
        indexLigneInfos: 0,
        updateDisabled: false,
        types: [
          { type: 'INTER', intitule: 'Inter-entreprises' },
          { type: 'INTRA', intitule: 'Intra-entreprise' },
        ],
        devis: {
          reference: '',
          numero: '',
          clientDestinataire: {},
          dateEmission: new Date(),
          dateEnvoi: null,
          dateEcheance: null,
          dateAcceptation: null,
          typeFormation: '',
          budget: {
            client: {},
            cout: 0,
            modeCalcul: '',
          },
          montantHT: 0,
          montantTVA: 0,
          montantTotal: 0,
          intitule: '',
          noDossier: '',
          frais: 0.0,
          etat: 'NOUVEAU',
          nbStagiaires: 0,
          adresse: {
            adressePostale: '',
            complement: null,
            batiment: null,
            etage: null,
            codePostal: '',
            ville: '',
            pays: null,
          },
          distanciel: false,
          formationAssociee: null,
          lieAFormation: false,
          dateDebut: null,
          dateFin: null,
          informationsAdditionnelles: {},
          lignesDevis: [],
        },
        menuDateDebut: null,
        dateDebutFormatted: '',
        menuDateFin: null,
        dateFinFormatted: '',
        budgetTotalIntra: 0,
        modeCalculBudget: 'TOTAL',
        displayModeCalcul: '',
        infosAdd: [{}],
        formations: [],
        isChezClient: false,
        pickerDate: null,
        joursFeries: [],
      };
    },

    beforeMount() {
      window.addEventListener('beforeunload', this.preventNav);
    },

    beforeDestroy() {
      window.addEventListener('beforeunload', this.preventNav);
    },

    beforeRouteLeave(to, from, next) {
      if (this.formEdited) {
        if (!window.confirm('Les modifications que vous avez apportées seront perdues, souhaitez-vous quitter la page ?')) {
          return;
        }
      }
      next();
    },

    created() {
      if (!this.$route.params.id) {
        // create mode
        this.axios.get('/devis/next')
          .then((res) => {
            this.devis.numero = res.data;
          });
      } else {
        // edit mode
        this.axios.get('/devis/' + this.$route.params.id)
          .then((res) => {
            this.devis = res.data
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la récupération du devis', type: 'warn' });
          });
      }

      this.axios.get('/clients')
        .then((res) => {
          this.clients = res.data.filter(f => f.type === 'DIRECT');
          this.clients.sort((a, b) => a.nomCommercial.localeCompare(b.raisonSocinomCommercialale));
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération des clients', type: 'warn' });
        });

      this.axios.get('/cours')
        .then((res) => {
          this.courses = res.data;
          this.courses.sort((a, b) => a.name.localeCompare(b.name));
        })
        .catch(e => {
          this.setSnack('Erreur lors de la récupération des cours (' + e + ')');
        });

      this.getJoursFeries();
    },

    watch: {
      'devis.dateDebut'(val) {
        this.dateDebutFormatted = this.formatDate(this.devis.dateDebut);
        this.menuDateDebut = false;
        this.openDateFin();
      },

      'devis.dateFin'(val) {
        this.dateFinFormatted = this.formatDate(this.devis.dateFin);
        this.menuDateDebut = false;
      },

      pickerDate(val) {
        this.setColorJoursFeries();
      },
    },

    methods: {
      validate() {
        this.formEdited = false;
        if (this.$refs.devis.validate()) {
          if (this.$route.params.id) {
            // edit mode
            this.updateDevis();
          } else {
            // create mode
            this.createDevis();
          }
        } else {
          this.setSnack({ text: 'Veuillez renseigner tous les champs obligatoires', type: 'warn' });
        }
      },

      updateReference() {
        let num = '000';
        const client = this.clients.filter((c) => c.id === this.devis.clientDestinataire.id)[0];
        if (this.devis.numero < 10) {
          num = '00' + this.devis.numero;
        } else if (this.devis.numero < 100) {
          num = '0' + this.devis.numero;
        } else {
          num = this.devis.numero;
        }
        this.devis.reference = 'DEV_' + num + '_' + client.codeFacture;
      },

      fillAdresse() {
        if (this.isChezClient) {
          this.devis.adresse = this.devis.clientDestinataire.adresse;
        } else {
          this.devis.adresse = {};
        }
      },

      getFormations() {
        if (this.devis.cours && this.devis.typeFormation) {
          this.axios.get('/formations')
            .then((res) => {
              if (this.devis.typeFormation === 'INTER') {
                // Récupérer les formations INTER dont le cours correspond à celui sélectionné, qui sont ouvertes ou confirmées
                this.formations = res.data.filter((f) => f.cours.id === this.devis.cours.id && (f.etat === 'OUVERT' || f.etat === 'CONFIRME') && f.type === 'INTER').sort((a, b) => new Date(b.dateDebut) - new Date(a.dateDebut));
              } else if (this.devis.typeFormation === 'INTRA') {
                // Récupérer les formations INTRA dont le cours correspond à celui sélectionné, qui sont ouvertes ou confirmées, et qui n'ont pas de client associé
                this.formations = res.data.filter((f) => f.cours.id === this.devis.cours.id && (f.etat === 'OUVERT' || f.etat === 'CONFIRME') && f.type === 'INTRA' && f.client == null).sort((a, b) => new Date(b.dateDebut) - new Date(a.dateDebut));
              }
            })
            .catch(e => {
              this.setSnack({ text: 'Erreur lors de la récupération des cours', type: 'warn' });
            });
        }
      },

      getCoutsPedagogiquesIntra() {
        if (this.modeCalculBudget === 'TOTAL') {
          return Number(this.devis.budget.cout);
        }
        if (this.modeCalculBudget === 'JOUR') {
          return this.devis.budget.cout * this.devis.cours.nbDays;
        }
        if (this.modeCalculBudget === 'HEURE') {
          return this.devis.budget.cout * (this.devis.cours.nbDays * 7);
        }
      },

      setBudgetModeCalcul() {
        this.devis.budget.modeCalcul = this.modeCalculBudget;

        if (this.modeCalculBudget === 'TOTAL') {
          this.displayModeCalcul = 'Prix total';
          this.devis.budget.cout = this.budgetTotalIntra;
        }
        if (this.modeCalculBudget === 'JOUR') {
          this.displayModeCalcul = 'Prix par jour';
          this.devis.budget.cout = this.budgetTotalIntra / this.devis.cours.nbDays;
        }
        if (this.modeCalculBudget === 'HEURE') {
          this.displayModeCalcul = 'Prix par heure';
          this.devis.budget.cout = this.budgetTotalIntra / (this.devis.cours.nbDays * 7);
        }
      },

      getInitBudgetTotalIntra() {
        if (this.modeCalculBudget === 'TOTAL') {
          this.displayModeCalcul = 'Prix total';
          return this.devis.budget.cout;
        }
        if (this.modeCalculBudget === 'JOUR') {
          this.displayModeCalcul = 'Prix par jour';
          return this.devis.budget.cout * this.devis.cours.nbDays;
        }
        if (this.modeCalculBudget === 'HEURE') {
          this.displayModeCalcul = 'Prix par heure';
          return this.devis.budget.cout * (this.devis.cours.nbDays * 7);
        }
      },

      setBudgetTotalIntra(value) {
        if (this.modeCalculBudget === 'TOTAL') {
          this.budgetTotalIntra = value;
        }
        if (this.modeCalculBudget === 'JOUR') {
          this.budgetTotalIntra = value * this.devis.cours.nbDays;
        }
        if (this.modeCalculBudget === 'HEURE') {
          this.budgetTotalIntra = value * (this.devis.cours.nbDays * 7);
        }
      },

      calculTotalHT() {
        let sousTotal = 0;
        if (this.devis.typeFormation === 'INTER') {
          sousTotal = this.devis.budget.cout * this.devis.nbStagiaires;
        } else if (this.devis.typeFormation === 'INTRA') {
          sousTotal = this.getCoutsPedagogiquesIntra();
        }

        if (this.devis.lignesDevis.length > 0) {
          for (const ligne of this.devis.lignesDevis) {
            sousTotal += (ligne.unites * ligne.prixUnitaire) || 0;
          }
        }

        sousTotal = Math.round((sousTotal + Number.EPSILON) * 100) / 100
        this.devis.montantHT = sousTotal;
        return sousTotal;
      },

      calculTVA() {
        let tva = this.devis.montantHT * 0.2 || 0;
        tva = Math.round((tva + Number.EPSILON) * 100) / 100
        this.devis.montantTVA = tva
        return tva;
      },

      calculTotalTTC() {
        let ttc = this.devis.montantHT * 1.2 || 0;
        ttc = Math.round((ttc + Number.EPSILON) * 100) / 100
        this.devis.montantTotal = ttc
        return ttc;
      },

      addLigneDetail() {
        this.devis.lignesDevis.push({});
        this.indexLigneDetail++;
      },

      removeLigneDetail() {
        this.devis.lignesDevis.pop();
        this.indexLigneDetail--;
      },

      addLigneInfos() {
        this.infosAdd.push({});
        this.indexLigneInfos++;
      },

      removeLigneInfos() {
        this.infosAdd.pop();
        this.indexLigneInfos--;
      },

      openDateFin() {
        let dateFinParsed;
        const dateToday = new Date().toISOString().substring(0, 10);
        const dateFin = new Date(this.devis.dateFin).toISOString().substring(0, 10);

        if (dateToday === dateFin || new Date(this.devis.dateFin) < new Date(this.devis.dateDebut)) {
          this.devis.dateFin = this.devis.dateDebut;

          if (this.devis.cours) {
            dateFinParsed = new Date(this.devis.dateFin);
            dateFinParsed.setDate(dateFinParsed.getDate() + this.devis.cours.nbDays - 1);
            this.devis.dateFin = dateFinParsed.toISOString().substring(0, 10);
          }

          this.menuDateFin = true;
        }
      },

      getJoursFeries() {
        this.axios('/planning/joursFeries/' + new Date().getFullYear())
          .then((res) => {
            for (const date of Object.entries(res.data)) {
              this.joursFeries.push(date[0]);
            }
            return this.axios('/planning/joursFeries/' + (new Date().getFullYear() + 1))
          })
          .then((res) => {
            for (const date of Object.entries(res.data)) {
              this.joursFeries.push(date[0]);
            }
            return res;
          })
      },

      createDevis() {
        this.infosAdd.forEach((obj) => {
          this.devis.informationsAdditionnelles[obj.titre] = obj.valeur;
        });

        if (this.devis.distanciel) {
          this.devis.adresse = null;
        }

        this.devis.budget.modeCalcul = this.modeCalculBudget;
        this.devis.budget.client = this.devis.clientDestinataire;

        this.axios.post('/devis', this.devis)
          .then(res => {
            this.setSnack({ text: 'Le devis a bien été généré', type: 'success' });
            this.$router.push({ name: 'DevisDetail', params: { id: res.data.id } });
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la génération du devis', type: 'warn' });
          })
      },

      updateDevis() {
        this.updateDisabled = true;
        this.axios.put('/devis/' + this.devis.id, this.devis)
          .then(response => {
            this.setSnack({ text: 'Le devis a bien été mis à jour', type: 'success' });
            this.$router.push({ name: 'DevisDetail', params: { id: this.devis.id } });
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la mise à jour du devis', type: 'warn' });
            this.updateDisabled = false;
          })
      },

      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },

      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },

      toEuro(num) {
        if (isNaN(num)) {
          num = 0;
        }
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }).format(num);
      },

      setColorJoursFeries() {
        // setTimeout de 10ms pour laisser le temps de s'afficher
        setTimeout(() => {
          var allDates = document.querySelectorAll('.v-date-picker-table .v-btn .v-btn__content');
          // filtrer uniquement les jours fériés sur le mois courant
          var dates = this.joursFeries.filter(jour => this.pickerDate === jour.substring(0, 7)).map(jour => parseInt(jour.split('-')[2]));

          allDates.forEach((x, i) => {
            if (dates.includes(parseInt(x.innerHTML))) {
              allDates[i].classList.add('date-gris');
            }
          })
        }, 10)
      },

      saveDateDebut(date) {
        this.$refs.menuDateDebut.save(date)
      },

      saveDateFin(date) {
        this.$refs.menuDateFin.save(date)
      },

      preventNav(event) {
        if (this.formEdited) {
          event.preventDefault();
          event.returnValue = '';
          this.formEdited = false;
        }
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }

</script>

<style scoped>
  /* Griser les weekends et jours fériés */
  .create-formation-date-picker >>> tr td:nth-child(6) .v-btn__content,
  .create-formation-date-picker >>> tr td:nth-child(7) .v-btn__content,
  .create-formation-date-picker >>> .date-gris
  {
    color: #b2b2b2;
  }

  #create-devis >>> .divider {
    position: relative;
  }

  #create-devis >>> .btnAjouterLigne {
    position: absolute;
    top: -14px;
    right: 0;
  }

  #create-devis >>> .btnRetirerLigne {
    position: absolute;
    top: -14px;
    right: 150px;
  }

  #table-recettes-budgets-inter thead th:nth-child(2),
  #table-recettes-budgets-intra thead th:nth-child(2) {
    width: 120px;
  }

  #table-recettes-budgets-inter thead th:nth-child(3),
  #table-recettes-budgets-intra thead th:nth-child(3) {
    width: 150px;
  }

  #create-devis >>> .input-budget input {
    text-align: right;
  }

  /* Chrome, Safari, Edge, Opera */
  #create-devis >>> .input-budget input::-webkit-outer-spin-button,
  #create-devis >>> .input-budget input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  /* Firefox */
  #create-devis >>> .input-budget input[type=number] {
    -moz-appearance: textfield;
  }

</style>
